.attachment {
    position: relative;
    padding: 15px 0 0 129px;
    @include clearfix;
}

.attachment__thumb {
    position: relative;
    float: left;
    margin-top: 5px;
    margin-left: -124px;
    padding-bottom: 15px;
}
.attachment__img{
    outline: 5px solid rgba(11,12,12,0.1);
    box-shadow: 0 2px 2px rgba(11,12,12,0.4);
    display: block;
    width: 99px;
    height: 140px;
    background: #fff;
}
.attachment__title {
    @include gamma;
    margin-bottom: $baseline/4;
}
.attachment__meta
{
    @include epsilon;
    color: get-color(mid-grey);
}