.departments-summary {
    border-bottom: 1px solid get-color(grey);
    padding-bottom: $baseline/2;
    
    &:after{
        content: ".";
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
    }
}

.departments-summary__count {
    margin-top: -3px;
    font-size: 3rem; /* we do not have a mixin for this font size */
    line-height: 1;
    text-transform: none;
    font-weight: $bold;
    float: left;
    padding-right: $gutter/2;
    color: get-color(mid-grey);
}

.departments-summary__text {
    @include delta;
    font-weight: $bold;
    line-height:1.5;
}