*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html,
body {
  background: #fff; }

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-y: scroll; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video, main {
  background: transparent;
  border: 0;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
  display: block; }

strong,
b {
  font-weight: 700; }

em,
i {
  font-style: italic; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

ins {
  text-decoration: none; }

mark {
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

dfn {
  font-style: italic; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  vertical-align: top; }

hr {
  border: 0;
  display: block;
  height: 1px;
  margin: 0;
  padding: 0; }

address {
  font-style: normal; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

figure {
  margin: 0; }

[hidden] {
  display: none; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  display: block;
  border: 0;
  vertical-align: middle;
  max-width: 100%;
  height: auto; }

a {
  text-decoration: none; }

a:hover,
a:focus,
a:active {
  text-decoration: none; }

input,
button {
  -moz-appearance: none;
  -webkit-appearance: none; }

input,
button,
select {
  border: 0 none;
  border-radius: 0;
  padding: 0;
  vertical-align: middle; }

input[type="search"] {
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none; }

input[type="checkbox"] {
  -moz-appearance: checkbox;
  -webkit-appearance: checkbox; }

input[type="radio"] {
  -moz-appearance: radio;
  -webkit-appearance: radio; }

input[type="range"] {
  -moz-appearance: slider-horizontal;
  -webkit-appearance: slider-horizontal; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-size: 99%;
  line-height: normal;
  margin: 0;
  vertical-align: baseline; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  border: 0 none;
  cursor: pointer;
  -webkit-appearance: button;
  background-color: transparent; }

button[disabled],
input[disabled] {
  cursor: default; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  width: 100%; }

[role="button"] {
  cursor: pointer; }

html {
  background-color: #004785; }

body {
  background-color: #fff; }

.color__primary-blue {
  color: #004785; }

.bg__primary-blue {
  background-color: #004785; }

.color__secondary-blue {
  color: #007DB3; }

.bg__secondary-blue {
  background-color: #007DB3; }

.color__link-color {
  color: #0076A8; }

.bg__link-color {
  background-color: #0076A8; }

.color__light-blue {
  color: #cfe5f7; }

.bg__light-blue {
  background-color: #cfe5f7; }

.color__highlight {
  color: #ffcd04; }

.bg__highlight {
  background-color: #ffcd04; }

.color__light-green {
  color: #DCEDC8; }

.bg__light-green {
  background-color: #DCEDC8; }

.color__green {
  color: #12875A; }

.bg__green {
  background-color: #12875A; }

.color__white {
  color: #fff; }

.bg__white {
  background-color: #fff; }

.color__off-white {
  color: #fafafa; }

.bg__off-white {
  background-color: #fafafa; }

.color__grey {
  color: #b2b2b2; }

.bg__grey {
  background-color: #b2b2b2; }

.color__light-grey {
  color: #eaeaea; }

.bg__light-grey {
  background-color: #eaeaea; }

.color__mid-grey {
  color: #707070; }

.bg__mid-grey {
  background-color: #707070; }

.color__off-black {
  color: #191919; }

.bg__off-black {
  background-color: #191919; }

.color__red {
  color: #d20c0c; }

.bg__red {
  background-color: #d20c0c; }

.color__dark-red {
  color: #8b0000; }

.bg__dark-red {
  background-color: #8b0000; }

.color__light-red {
  color: #f1e5e5; }

.bg__light-red {
  background-color: #f1e5e5; }

.color__turquoise {
  color: #28a197; }

.bg__turquoise {
  background-color: #28a197; }

.color__isd {
  color: #6c2c91; }

.bg__isd {
  background-color: #6c2c91; }

.color__logistics {
  color: #00a94f; }

.bg__logistics {
  background-color: #00a94f; }

.color__snbts {
  color: #db3328; }

.bg__snbts {
  background-color: #db3328; }

.color__hps {
  color: #78a541; }

.bg__hps {
  background-color: #78a541; }

.color__legal {
  color: #00704a; }

.bg__legal {
  background-color: #00704a; }

.color__practitioner {
  color: #7e3661; }

.bg__practitioner {
  background-color: #7e3661; }

.color__it {
  color: #01a19a; }

.bg__it {
  background-color: #01a19a; }

.color__facilities {
  color: #5e937f; }

.bg__facilities {
  background-color: #5e937f; }

.color__pgms {
  color: #625faa; }

.bg__pgms {
  background-color: #625faa; }

.color__procurement {
  color: #e46d25; }

.bg__procurement {
  background-color: #e46d25; }

.color__commissioning {
  color: #ec008c; }

.bg__commissioning {
  background-color: #ec008c; }

.color__fraud {
  color: #bb0371; }

.bg__fraud {
  background-color: #bb0371; }

@font-face {
  font-family: "Neue Helvetica W01_n3";
  src: url("/static/fonts/1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix") format("eot"); }

@font-face {
  font-family: "Neue Helvetica W01";
  src: url("/static/fonts/1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix");
  src: url("/static/fonts/1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix") format("eot"), url("/static/fonts/3a46542f-f429-4821-9a88-62e3be54a640.woff2") format("woff2"), url("/static/fonts/50ac1699-f3d2-47b6-878f-67a368a17c41.woff") format("woff"), url("/static/fonts/0be5590f-8353-4ef3-ada1-43ac380859f8.ttf") format("truetype"), url("/static/fonts/82a4e1c2-7ffd-4c58-86fa-4010a6723c8e.svg#82a4e1c2-7ffd-4c58-86fa-4010a6723c8e") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Neue Helvetica W01_n4";
  src: url("/static/fonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix") format("eot"); }

@font-face {
  font-family: "Neue Helvetica W01";
  src: url("/static/fonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix");
  src: url("/static/fonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix") format("eot"), url("/static/fonts/3dac71eb-afa7-4c80-97f0-599202772905.woff2") format("woff2"), url("/static/fonts/34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.woff") format("woff"), url("/static/fonts/8a8bfee3-197c-4942-9b11-71508cc9f406.ttf") format("truetype"), url("/static/fonts/5101995a-e73b-4cf9-84e8-f99eb43277b1.svg#5101995a-e73b-4cf9-84e8-f99eb43277b1") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Neue Helvetica W01_n7";
  src: url("/static/fonts/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix") format("eot"); }

@font-face {
  font-family: "Neue Helvetica W01";
  src: url("/static/fonts/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix");
  src: url("/static/fonts/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix") format("eot"), url("/static/fonts/531c5a28-5575-4f58-96d4-a80f7b702d7b.woff2") format("woff2"), url("/static/fonts/439c5962-f9fe-4eaf-a1f6-f41d42edba75.woff") format("woff"), url("/static/fonts/419a308d-b777-4f84-9235-2caf4a98ec23.ttf") format("truetype"), url("/static/fonts/e4d5b881-6835-45b7-8766-3e5b9e7cab8b.svg#e4d5b881-6835-45b7-8766-3e5b9e7cab8b") format("svg");
  font-weight: 700;
  font-style: normal; }

body,
button,
input,
select,
textarea {
  font: 400 100% "Neue Helvetica W01", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
  color: #191919; }

.light {
  font-weight: 300; }

.regular {
  font-weight: 400; }

.bold {
  font-weight: 700; }

.alpha {
  font-size: 2rem;
  line-height: 1.2;
  font-size: 2.6rem; }

.beta {
  font-size: 1.6rem;
  line-height: 1.2;
  font-size: 1.8rem; }

.gamma {
  font-size: 1.3rem;
  line-height: 1.2;
  font-size: 1.5rem; }

.delta {
  font-size: 1.15rem;
  line-height: 1.2; }

.epsilon {
  font-size: 1rem;
  line-height: 1.2; }

.zeta {
  font-size: 0.85rem;
  line-height: 1.2; }

p,
li,
dt,
dd,
address {
  font-size: 1.15rem;
  line-height: 1.2;
  max-width: 40em; }

table {
  font-size: 1.15rem;
  line-height: 1.2; }

.heading-xl {
  font-size: 2rem;
  line-height: 1.2;
  font-size: 2.6rem;
  margin-bottom: 0.75rem; }

.heading-l {
  font-size: 1.6rem;
  line-height: 1.2;
  font-size: 1.8rem;
  margin-bottom: 0.375rem; }

.heading-m {
  font-size: 1.3rem;
  line-height: 1.2;
  font-size: 1.5rem;
  margin-bottom: 0.375rem; }

.heading-s {
  font-size: 1.15rem;
  line-height: 1.2;
  margin-bottom: 0.375rem; }

.caption-xl, .caption-l, .caption-m {
  display: block;
  color: #707070;
  margin-bottom: 0.375rem;
  font-weight: 400; }

.caption-xl {
  font-size: 1.6rem;
  line-height: 1.2;
  font-size: 1.8rem; }

.caption-l {
  font-size: 1.3rem;
  line-height: 1.2;
  font-size: 1.5rem; }

.caption-m {
  font-size: 1.15rem;
  line-height: 1.2; }

.align-centre {
  text-align: center; }

.align-right {
  text-align: right; }

.align-left {
  text-align: left; }

.no-webfonts body,
.no-webfonts button,
.no-webfonts input,
.no-webfonts select,
.no-webfonts textarea {
  font-family: sans-serif; }

.wysiwyg h1 {
  font-size: 2rem;
  line-height: 1.2;
  font-size: 2.6rem;
  margin-bottom: 0.75rem; }

.wysiwyg h2 {
  font-size: 1.6rem;
  line-height: 1.2;
  font-size: 1.8rem;
  margin-bottom: 0.375rem; }

.wysiwyg h3 {
  font-size: 1.3rem;
  line-height: 1.2;
  font-size: 1.5rem;
  margin-bottom: 0.375rem; }

.wysiwyg h4, .wysiwyg h5, .wysiwyg h6 {
  font-size: 1.15rem;
  line-height: 1.2;
  margin-bottom: 0.375rem; }

.wysiwyg p, .wysiwyg ul, .wysiwyg ol {
  line-height: 1.4;
  margin-bottom: 1.5rem; }

.wysiwyg ul {
  list-style-type: disc;
  padding-left: 24px; }

.wysiwyg ol {
  padding-left: 24px;
  list-style-type: decimal; }

.wysiwyg li {
  margin-bottom: 0.375rem; }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  white-space: nowrap; }

.hidden {
  display: none; }

.hide--small {
  display: none;
  display: block; }

.show-for-large-up {
  display: none;
  display: block; }

.visible {
  position: static;
  visibility: visible;
  width: auto;
  height: auto;
  padding: 0;
  margin: auto;
  overflow: inherit;
  clip: auto; }

@media screen {
  .print-only {
    display: none; } }

@media print {
  .print-hide {
    display: none; } }

.grid-wrap {
  width: auto;
  max-width: 1080px;
  margin: 0 auto; }

.grid-wrap--soft {
  padding-left: 12px;
  padding-right: 12px; }

.grid-row {
  clear: both;
  font-size: 0; }
  .grid-row:after {
    content: '';
    display: table;
    clear: both; }

.grid-row--soft-flush {
  margin-left: -12px;
  margin-right: -12px; }

.grid-col {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  font-size: initial; }

.grid-col--soft {
  padding-left: 12px;
  padding-right: 12px; }

.grid-xsmall-1 {
  width: 8.33333%; }

.grid-xsmall-2 {
  width: 16.66667%; }

.grid-xsmall-3 {
  width: 25%; }

.grid-xsmall-4 {
  width: 33.33333%; }

.grid-xsmall-5 {
  width: 41.66667%; }

.grid-xsmall-6 {
  width: 50%; }

.grid-xsmall-7 {
  width: 58.33333%; }

.grid-xsmall-8 {
  width: 66.66667%; }

.grid-xsmall-9 {
  width: 75%; }

.grid-xsmall-10 {
  width: 83.33333%; }

.grid-xsmall-11 {
  width: 91.66667%; }

.grid-xsmall-12 {
  width: 100%; }

.grid-small-1 {
  width: 8.33333%; }

.grid-small-2 {
  width: 16.66667%; }

.grid-small-3 {
  width: 25%; }

.grid-small-4 {
  width: 33.33333%; }

.grid-small-5 {
  width: 41.66667%; }

.grid-small-6 {
  width: 50%; }

.grid-small-7 {
  width: 58.33333%; }

.grid-small-8 {
  width: 66.66667%; }

.grid-small-9 {
  width: 75%; }

.grid-small-10 {
  width: 83.33333%; }

.grid-small-11 {
  width: 91.66667%; }

.grid-small-12 {
  width: 100%; }

.grid-medium-1 {
  width: 8.33333%; }

.grid-medium-2 {
  width: 16.66667%; }

.grid-medium-3 {
  width: 25%; }

.grid-medium-4 {
  width: 33.33333%; }

.grid-medium-5 {
  width: 41.66667%; }

.grid-medium-6 {
  width: 50%; }

.grid-medium-7 {
  width: 58.33333%; }

.grid-medium-8 {
  width: 66.66667%; }

.grid-medium-9 {
  width: 75%; }

.grid-medium-10 {
  width: 83.33333%; }

.grid-medium-11 {
  width: 91.66667%; }

.grid-medium-12 {
  width: 100%; }

.grid-large-1 {
  width: 8.33333%; }

.grid-large-2 {
  width: 16.66667%; }

.grid-large-3 {
  width: 25%; }

.grid-large-4 {
  width: 33.33333%; }

.grid-large-5 {
  width: 41.66667%; }

.grid-large-6 {
  width: 50%; }

.grid-large-7 {
  width: 58.33333%; }

.grid-large-8 {
  width: 66.66667%; }

.grid-large-9 {
  width: 75%; }

.grid-large-10 {
  width: 83.33333%; }

.grid-large-11 {
  width: 91.66667%; }

.grid-large-12 {
  width: 100%; }

.global-header {
  padding: 0.5rem 0;
  background-color: #004785;
  position: relative; }
  .global-header:after {
    content: '';
    display: table;
    clear: both; }

.global-header__link {
  display: inline-block; }

.global-header__img {
  margin-top: 1px;
  display: inline-block; }

.global-header__title {
  font-size: 1rem;
  line-height: 1.2;
  color: #fff;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.global-header__search-form {
  width: 100%;
  max-width: inherit; }
  .global-header__search-form.is--open {
    display: block; }

.global-header__toggle-search {
  background: url("/static/img/icons/search.svg") center no-repeat;
  background-size: 18px 18px;
  position: absolute;
  right: 12px;
  top: 0.5rem;
  bottom: 2px;
  padding: 0;
  height: 34px;
  width: 34px;
  display: none; }
  .global-header__toggle-search:focus {
    outline: 4px solid #ffcd04; }
  .global-header__toggle-search.is--active {
    display: none; }

.local-header {
  border-bottom: 1px solid #eaeaea;
  position: relative; }

.local-header--isd {
  border-top: 5px solid #6c2c91; }

.local-header--logistics {
  border-top: 5px solid #00a94f; }

.local-header--snbts {
  border-top: 5px solid #db3328; }

.local-header--hps {
  border-top: 5px solid #78a541; }

.local-header--legal {
  border-top: 5px solid #00704a; }

.local-header--practitioner {
  border-top: 5px solid #7e3661; }

.local-header--it {
  border-top: 5px solid #01a19a; }

.local-header--facilities {
  border-top: 5px solid #5e937f; }

.local-header--pgms {
  border-top: 5px solid #625faa; }

.local-header--procurement {
  border-top: 5px solid #e46d25; }

.local-header--commissioning {
  border-top: 5px solid #ec008c; }

.local-header--fraud {
  border-top: 5px solid #bb0371; }

.local-header__masthead {
  margin: 0.75rem 0 0.75rem 0; }

.local-header__logo-link {
  vertical-align: middle;
  display: inline-block;
  text-decoration: none; }

.lte-ie8 .local-header__logo-link {
  display: inherit; }

.local-header__logo-img {
  display: none;
  display: inline-block;
  margin-right: 12px; }

.local-header-logo__img {
  min-width: 240px; }

.local-header__heading {
  font-size: 1.3rem;
  line-height: 1.2;
  font-size: 1.5rem;
  display: inline-block;
  vertical-align: middle;
  font-weight: 700;
  color: #191919;
  font-size: 1.15rem;
  line-height: 1.2;
  max-width: 20rem;
  font-size: 1.3rem;
  line-height: 1.2;
  font-size: 1.5rem;
  max-width: 100%; }

.global-footer {
  clear: both;
  border-top: 4px solid #007DB3;
  background-color: #004785;
  padding: 3rem 24px; }

.global-footer__logo {
  display: inline-block; }

.global-footer__list {
  margin-bottom: 2.25rem; }

.global-footer__item {
  padding-bottom: 6px; }

.global-footer__title {
  font-size: 1.15rem;
  line-height: 1.2;
  color: #fff;
  margin-bottom: 0.375rem; }

.global-footer__link {
  color: #fff;
  font-size: 1rem;
  line-height: 1.2;
  display: inline-block; }
  .global-footer__link:focus, .global-footer__link:hover {
    color: #eaeaea; }

.article-header {
  padding-bottom: 1.5rem; }
  .article-header:after {
    content: '';
    background-color: #007DB3;
    display: block;
    width: 80px;
    height: 3px;
    margin-top: 1.5rem; }

.push {
  margin: 1.5rem; }

.push__bottom--half {
  margin-bottom: 0.75rem; }

.push__bottom {
  margin-bottom: 1.5rem; }

.push__bottom--double {
  margin-bottom: 3rem; }

.push__top {
  margin-top: 1.5rem; }

.push__top--double {
  margin-top: 3rem; }

.push__right {
  margin-right: 1.5rem; }

.push__left {
  margin-left: 1.5rem; }

.push__sides {
  margin-left: 1.5rem;
  margin-right: 1.5rem; }

.push__ends {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem; }

.push__ends--double {
  margin-bottom: 3rem;
  margin-top: 3rem; }

.push__top--half {
  margin-top: 0.75rem; }

.soft {
  padding: 24px; }

.soft__sides {
  padding-left: 24px;
  padding-right: 24px; }

.soft__right {
  padding-right: 24px; }

.soft__left {
  padding-left: 24px; }

.soft__half {
  padding: 12px; }

.soft__sides--half {
  padding-left: 12px;
  padding-right: 12px; }

.soft__top {
  padding-top: 1.5rem; }

.soft__top--double {
  padding-top: 3rem; }

.soft__bottom {
  padding-bottom: 1.5rem; }

.soft__bottom--double {
  padding-bottom: 3rem; }

.soft__ends {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.soft__ends--double {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.clip {
  overflow: hidden; }

.block {
  display: block; }

.relative {
  position: relative; }

.main-wrapper {
  margin-top: 0.75rem;
  margin-bottom: 3rem; }

.main-wrapper--l {
  margin-top: 1.5rem;
  margin-top: 3rem; }

.list-disc {
  padding-left: 24px;
  list-style-type: disc; }

.list-disc__item {
  margin-bottom: 0.375rem; }
  .list-disc__item.is--active {
    font-weight: 700; }

.list-number {
  padding-left: 24px;
  list-style-type: decimal; }

.list-number__item {
  margin-bottom: 0.375rem; }

a {
  text-decoration: underline;
  color: #0076A8; }

a:focus {
  color: #0076A8;
  text-decoration: none;
  outline: 4px solid #ffcd04 !important; }

a:hover {
  color: #0076A8;
  text-decoration: none; }

.skip__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none; }

.skip__btn {
  position: relative;
  left: -999em;
  height: 1px; }
  .skip__btn:focus {
    pointer-events: all;
    position: fixed;
    height: auto;
    margin: 0 auto;
    display: block;
    background-color: #004785;
    color: #fff;
    padding: 0 0.75rem;
    left: 12px;
    top: 12px;
    z-index: 500;
    text-align: center; }

.back-link {
  font-size: 1rem;
  line-height: 1.2;
  display: inline-block;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 14px;
  border-bottom: 1px solid #0076A8;
  text-decoration: none; }
  .back-link:before {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    clip-path: polygon(0% 50%, 100% 100%, 100% 0%);
    border-width: 5px 6px 5px 0;
    border-right-color: inherit;
    content: "";
    position: absolute;
    top: -1px;
    bottom: 1px;
    left: 0;
    margin: auto; }

.panel {
  text-align: center;
  padding: 2.25rem;
  margin-bottom: 1.5rem; }

.panel__title {
  font-size: 2rem;
  line-height: 1.2;
  font-size: 2.6rem; }

.panel__body {
  font-size: 1.6rem;
  line-height: 1.2;
  font-size: 1.8rem; }

.panel--attention {
  background-color: #d20c0c;
  color: #fff; }

.panel--info {
  background-color: #007DB3;
  color: #fff; }

.panel--success {
  background-color: #28a197;
  color: #fff; }

.btn {
  font-size: 1.15rem;
  line-height: 1.2;
  background-color: #12875A;
  border: 0;
  display: inline-block;
  padding: 0.5rem 1.5rem;
  text-align: center;
  text-decoration: none;
  transition: all 0.15s linear;
  box-shadow: 0 2px 0 #073624;
  width: 100%;
  margin-bottom: 0.375rem;
  color: #fff;
  position: relative;
  width: auto; }
  .btn:hover {
    background-color: #0e6c48; }
  .btn[disabled], .btn[disabled="disabled"] {
    opacity: .6;
    cursor: default; }
    .btn[disabled]:hover, .btn[disabled="disabled"]:hover {
      background-color: #12875A; }
    .btn[disabled]:active, .btn[disabled="disabled"]:active {
      top: 0; }
  .btn:focus {
    outline: 4px solid #ffcd04; }
  .btn:link, .btn:visited {
    color: #fff; }
  .btn:active {
    top: 2px; }
  .btn:active::before {
    top: -2px; }

.btn--secondary {
  background-color: #eaeaea;
  color: #191919;
  box-shadow: 0 2px 0 #8c8c8c; }
  .btn--secondary:link, .btn--secondary:visited {
    color: #191919; }
  .btn--secondary:hover {
    color: #191919;
    background-color: lightgray; }
  .btn--secondary[disabled]:hover, .btn--secondary[disabled="disabled"]:hover {
    background-color: #eaeaea; }

.btn--start {
  font-weight: 700; }

.card {
  background-color: #fff;
  margin-bottom: 1.5rem; }

.card--on-white {
  border: 1px solid #eaeaea; }

.card__body {
  padding: 1.5rem; }

.card__title {
  font-size: 1.15rem;
  line-height: 1.2;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem; }

.card__meta, .card__date {
  font-size: 0.85rem;
  line-height: 1.2;
  color: #707070;
  display: block; }

.card__meta {
  margin-bottom: 0.375rem; }

.card__date {
  margin-top: 1.5rem; }

.card__summary {
  font-size: 1rem;
  line-height: 1.2;
  color: #191919; }

.card__link {
  display: inline-block;
  text-decoration: none; }
  .card__link:hover {
    text-decoration: underline; }

.card--clickable {
  position: relative; }
  .card--clickable .card__link:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0); }

.category-item {
  border-left: 4px solid #eaeaea;
  padding-left: 12px;
  margin-bottom: 1.5rem; }

.category-item__title {
  font-size: 1.15rem;
  line-height: 1.2; }

.category-item__link {
  display: inline-block;
  text-decoration: none; }
  .category-item__link:hover {
    text-decoration: underline; }

.category-item__summary {
  font-size: 1rem;
  line-height: 1.2;
  color: #191919; }

.category-item__meta {
  font-size: 0.85rem;
  line-height: 1.2;
  color: #707070;
  display: block;
  margin-bottom: 0.375rem; }

.category-item--clickable {
  position: relative; }
  .category-item--clickable .category-item__link:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0); }

.form-group {
  margin-bottom: 1.5rem; }
  .form-group .form-group:last-of-type {
    margin-bottom: 0; }

.form-group--error {
  border-left: 4px solid #d20c0c;
  padding-left: 1rem; }

.fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  min-width: 0; }

.fieldset__legend {
  font-size: 1.15rem;
  line-height: 1.2;
  font-weight: 700;
  display: table;
  max-width: 100%;
  margin-bottom: 0.75rem;
  padding: 0;
  overflow: hidden;
  white-space: normal; }
  .fieldset__legend:after {
    content: '';
    display: table;
    clear: both; }

.fieldset__legend--xl {
  font-size: 2rem;
  line-height: 1.2;
  font-size: 2.6rem; }

.fieldset__legend--l {
  font-size: 1.6rem;
  line-height: 1.2;
  font-size: 1.8rem; }

.fieldset__legend--m {
  font-size: 1.3rem;
  line-height: 1.2;
  font-size: 1.5rem; }

.fieldset__legend--s {
  font-size: 1.15rem;
  line-height: 1.2; }

.label {
  font-size: 1.15rem;
  line-height: 1.2;
  font-weight: 700;
  cursor: pointer;
  display: block;
  margin-bottom: 0.375rem; }

.label--xl {
  font-size: 2rem;
  line-height: 1.2;
  font-size: 2.6rem;
  margin-bottom: 0.75rem; }

.label--l {
  font-size: 1.6rem;
  line-height: 1.2;
  font-size: 1.8rem;
  margin-bottom: 0.75rem; }

.label--m {
  font-size: 1.3rem;
  line-height: 1.2;
  font-size: 1.5rem; }

.label--s {
  font-size: 1.15rem;
  line-height: 1.2; }

.checkbox {
  display: block;
  position: relative;
  min-height: 40px;
  margin-bottom: 0.6rem;
  padding: 0 0 0 40px;
  clear: left; }

.checkbox__input {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 0;
  opacity: 0; }

.checkbox__label {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  padding: 8px 16px; }
  .checkbox__label:before {
    font-size: 1.15rem;
    line-height: 1.2;
    content: "";
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border: 2px solid currentColor;
    background: transparent; }
  .checkbox__label:after {
    content: "";
    position: absolute;
    top: 13px;
    left: 11px;
    width: 20px;
    height: 10px;
    transform: rotate(-45deg);
    border: solid;
    border-width: 0 0 4px 4px;
    border-top-color: transparent;
    opacity: 0;
    background: transparent; }

.checkbox__input:checked + .checkbox__label:after {
  opacity: 1; }

.checkbox__input:focus + .checkbox__label:before {
  outline: 4px solid #ffcd04; }

.fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  min-width: 0; }

.fieldset__legend {
  font-size: 1.15rem;
  line-height: 1.2;
  font-weight: 700;
  display: table;
  max-width: 100%;
  margin-bottom: 0.75rem;
  padding: 0;
  overflow: hidden;
  white-space: normal; }
  .fieldset__legend:after {
    content: '';
    display: table;
    clear: both; }

.fieldset__legend--xl {
  font-size: 2rem;
  line-height: 1.2;
  font-size: 2.6rem; }

.fieldset__legend--l {
  font-size: 1.6rem;
  line-height: 1.2;
  font-size: 1.8rem; }

.fieldset__legend--m {
  font-size: 1.3rem;
  line-height: 1.2;
  font-size: 1.5rem; }

.fieldset__legend--s {
  font-size: 1.15rem;
  line-height: 1.2; }

.input {
  font-size: 1.15rem;
  line-height: 1.2;
  max-width: 650px;
  width: 100%;
  display: block;
  padding: 0 12px;
  border: 2px solid #666;
  appearance: none;
  height: 40px;
  line-height: 36px; }
  .input::-webkit-outer-spin-button, .input::-webkit-inner-spin-button {
    -webkit-appearance: none; }
  .input:focus {
    outline: 4px solid #ffcd04; }

.input[type="number"] {
  -moz-appearance: textfield; }

.input--error {
  border: 2px solid #d20c0c; }

.file-upload {
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.2;
  max-width: 14rem; }
  .file-upload:focus {
    outline: 4px solid #ffcd04; }

.radio {
  display: block;
  position: relative;
  min-height: 40px;
  margin-bottom: 0.6rem;
  padding: 0 0 0 40px;
  clear: left; }

.radio__input {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 0;
  opacity: 0; }

.radio__label {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  padding: 8px 16px; }
  .radio__label:before {
    content: "";
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid currentColor;
    background: transparent; }
  .radio__label:after {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    width: 0;
    height: 0;
    border: 10px solid currentColor;
    border-radius: 50%;
    opacity: 0;
    background: currentColor; }

.radio__input:checked + .radio__label:after {
  opacity: 1; }

.radio__input:focus + .radio__label:before {
  box-shadow: 0 0 0 4px #ffcd04; }

.checkbox {
  display: block;
  position: relative;
  min-height: 40px;
  margin-bottom: 0.6rem;
  padding: 0 0 0 40px;
  clear: left; }

.checkbox__input {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 0;
  opacity: 0; }

.checkbox__label {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  padding: 8px 16px; }
  .checkbox__label:before {
    font-size: 1.15rem;
    line-height: 1.2;
    content: "";
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border: 2px solid currentColor;
    background: transparent; }
  .checkbox__label:after {
    content: "";
    position: absolute;
    top: 13px;
    left: 11px;
    width: 20px;
    height: 10px;
    transform: rotate(-45deg);
    border: solid;
    border-width: 0 0 4px 4px;
    border-top-color: transparent;
    opacity: 0;
    background: transparent; }

.checkbox__input:checked + .checkbox__label:after {
  opacity: 1; }

.checkbox__input:focus + .checkbox__label:before {
  outline: 4px solid #ffcd04; }

.select {
  font-size: 1.15rem;
  line-height: 1.2;
  max-width: 650px;
  width: 100%;
  display: block;
  padding: 4px 12px;
  border: 2px solid #666;
  cursor: pointer;
  height: 40px; }
  .select:focus {
    outline: 4px solid #ffcd04; }

.lte-ie8 .select {
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px; }

.select--error {
  border: 2px solid #d20c0c; }

.textarea {
  font-size: 1.15rem;
  line-height: 1.2;
  max-width: 650px;
  width: 100%;
  display: block;
  padding: 0.75rem 12px;
  border: 2px solid #666; }
  .textarea:focus {
    outline: 4px solid #ffcd04; }

.textarea--error {
  border: 2px solid #d20c0c; }

.date {
  font-size: 0; }

.date__item {
  display: inline-block;
  margin-right: 0.75rem; }

.date__label {
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 0.375rem; }

.date__input--day, .date__input--month {
  width: 50px; }

.date__input--year {
  width: 75px; }

.error-message {
  font-size: 1.15rem;
  line-height: 1.2;
  display: block;
  color: #d20c0c;
  margin-bottom: 0.75rem;
  font-weight: 700; }

.error-message--checkbox {
  margin-bottom: 0.375rem; }

.fieldset__legend:not(.fieldset__legend--xl):not(.fieldset__legend--l):not(.fieldset__legend--m) + .error-message {
  margin-top: -0.375rem; }

.error-summary {
  border: 4px solid #d20c0c;
  padding: 1.5rem 1rem;
  margin-bottom: 1.5rem; }
  .error-summary:focus {
    outline: 4px solid #ffcd04; }

.error-summary__item {
  margin-bottom: 0.375rem; }

.error-summary__link {
  color: #d20c0c;
  font-weight: 700; }
  .error-summary__link:hover, .error-summary__link:focus {
    color: #d20c0c; }

.hint {
  font-size: 1.15rem;
  line-height: 1.2;
  color: #707070;
  display: block;
  margin-bottom: 0.75rem;
  font-weight: 400; }

.fieldset__legend:not(.fieldset__legend--xl):not(.fieldset__legend--l):not(.fieldset__legend--m) + .hint {
  margin-top: -0.375rem; }

.modal {
  display: none;
  background-color: #191919;
  background-color: rgba(0, 0, 0, 0.8); }
  .modal.is--active {
    display: block;
    position: fixed;
    width: auto;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 600; }

.modal__inner {
  width: 85%;
  max-width: 1024px;
  background: #fff;
  margin: 6rem auto;
  padding: 2.25rem 24px 1.5rem 24px;
  position: relative;
  z-index: 700;
  padding-top: 1.5rem; }

.modal__close-btn {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 0;
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  background: url("/static/img/icons/close.png") center no-repeat;
  z-index: 700;
  top: 0.75rem;
  right: 12px; }
  .modal__close-btn:focus {
    outline: 4px solid #ffcd04; }

.toggle.is--active {
  display: block; }

.tabs:after {
  content: '';
  display: table;
  clear: both; }

.tabs__nav {
  display: block; }

.tabs__nav-item {
  font-size: 1.15rem;
  line-height: 1.2;
  border: 2px solid #b2b2b2;
  border-bottom: 0 none;
  display: block;
  padding: 0.75rem 24px;
  text-decoration: none;
  display: inline-block;
  border-color: transparent; }
  .tabs__nav-item:focus, .tabs__nav-item.is--active {
    position: relative; }
    .tabs__nav-item:focus:before, .tabs__nav-item.is--active:before {
      background-color: #004785;
      content: '';
      left: -2px;
      top: -2px;
      bottom: -2px;
      width: 4px;
      position: absolute; }
  .tabs__nav-item:focus, .tabs__nav-item.is--active {
    border-color: #b2b2b2;
    background-color: #fff; }
    .tabs__nav-item:focus:before, .tabs__nav-item.is--active:before {
      left: -2px;
      right: -2px;
      top: -2px;
      bottom: auto;
      width: auto;
      height: 4px; }

.tabs__bd:after {
  content: '';
  display: table;
  clear: both; }

.tabs__section {
  display: none;
  padding: 1.5rem; }
  .tabs__section.is--active {
    border: 2px solid #b2b2b2;
    display: block;
    margin-top: -2px; }
    .tabs__section.is--active:after {
      content: '';
      display: table;
      clear: both; }

.no-js .tabs__section {
  border: 2px solid #b2b2b2;
  display: block;
  margin-top: -2px; }
  .no-js .tabs__section:after {
    content: '';
    display: table;
    clear: both; }

.search {
  position: relative;
  display: table;
  border-collapse: separate;
  width: 100%;
  max-width: 650px;
  line-height: 24px; }

.search__input {
  display: table-cell;
  position: relative;
  border: solid 1px #b2b2b2;
  float: left;
  height: 40px;
  margin-bottom: 0; }
  .search__input:focus {
    outline-offset: -3px; }

.search__btn {
  position: relative;
  background-color: #007DB3;
  background-image: url("/static/img/icons/search.svg");
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 6px;
  width: 40px;
  height: 40px;
  vertical-align: middle; }
  .search__btn:focus {
    outline: 4px solid #ffcd04;
    outline-offset: -3px; }

.search__btn-wrapper {
  display: table-cell;
  width: 1%;
  vertical-align: middle; }

/* search modifiers */
.search--small .search__input, .search--small .search__btn {
  height: 34px; }

.search--small .search__btn {
  width: 34px; }

.search--large .search__input, .search--large .search__btn {
  height: 50px; }

.search--large .search__btn {
  width: 50px;
  background-size: 22px 22px; }

.lte-ie8 .search__btn {
  background-image: url("/static/img/icons/search.png"); }

.breadcrumb__list {
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  display: block; }
  .breadcrumb__list:after {
    content: '';
    display: table;
    clear: both; }

.breadcrumb__item {
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 0.25rem;
  padding-left: 25px;
  background: url("/static/img/icons/arrow-right.png") left top no-repeat;
  float: left; }
  .breadcrumb__item:first-child {
    background: transparent;
    padding-left: 0; }

.breadcrumb__link {
  text-decoration: underline; }

.pagination__list {
  text-align: right;
  font-size: 0; }
  .pagination__list:after {
    content: '';
    display: table;
    clear: both; }

.pagination__item {
  width: 50%;
  display: inline-block;
  vertical-align: top; }

.pagination__item--previous {
  text-align: left;
  float: left; }

.pagination__item--next {
  text-align: right; }

.pagination__link {
  display: inline-block;
  text-decoration: none; }

.pagination__link--previous {
  padding-left: 40px;
  background: url("/static/img/icons/arrow-left--36.png") left no-repeat; }

.pagination__link--next {
  text-align: right;
  padding-right: 40px;
  background: url("/static/img/icons/arrow-right--36.png") right no-repeat; }

.pagination__label {
  font-size: 1.15rem;
  line-height: 1.2; }

.pagination__detail {
  font-size: 0.85rem;
  line-height: 1.2;
  color: #191919; }

.collapsable {
  position: relative;
  margin-bottom: 0.75rem; }

.collapsable__btn {
  padding-left: 36px; }
  .collapsable__btn:before, .collapsable__btn:after {
    position: absolute;
    content: '';
    display: block;
    background-color: #191919; }
  .collapsable__btn:before {
    top: 0.6rem;
    left: 6px;
    width: 14px;
    height: 2px; }
  .collapsable__btn:after {
    top: 0.6rem;
    left: 6px;
    margin-top: -6px;
    margin-left: 6px;
    width: 2px;
    height: 14px; }
  .collapsable__btn.is--active:after {
    display: none; }
  .collapsable__btn:focus {
    outline: 4px solid #ffcd04; }

.collapsable__bd {
  padding-left: 36px;
  padding-top: 0.375rem;
  display: none; }
  .collapsable__bd.is--active {
    display: block; }

.no-js .collapsable__bd {
  display: block; }

.no-js .collapsable__btn:before, .no-js .collapsable__btn:after {
  display: none; }

.primary-nav__btn {
  font-size: 0.85rem;
  line-height: 1.2;
  border-left: 1px solid #006b9a;
  color: #fff;
  height: 100%;
  padding-left: 12px;
  position: absolute;
  right: 36px;
  top: 0;
  right: 0; }

.primary-nav {
  background-color: #00396c;
  padding: 3rem 0 1.5rem 0;
  display: none; }
  .primary-nav.is--active {
    display: block; }

.primary-nav__btn--on {
  display: none; }

.is--active .primary-nav__btn--off {
  display: none; }

.is--active .primary-nav__btn--on {
  display: block; }

.nav-primary__list {
  margin-bottom: 1.5rem; }

.nav-primary__item {
  padding-bottom: 6px; }

.nav-primary__link {
  font-size: 1rem;
  line-height: 1.2;
  color: #fff;
  display: inline-block;
  text-decoration: none; }
  .nav-primary__link:hover {
    color: #eaeaea;
    text-decoration: underline; }

.nav-primary__title {
  font-size: 1.15rem;
  line-height: 1.2;
  color: #fff;
  font-weight: 300;
  border-bottom: 1px solid #007DB3;
  margin-bottom: 0.375rem;
  padding-bottom: 6px; }

.nav-primary__logo {
  max-width: 100px;
  width: 100%; }

.phase-banner {
  padding: 0.75rem 0;
  border-bottom: 1px solid #eaeaea; }

.phase__tag {
  font-size: 1rem;
  line-height: 1.2;
  display: inline-block;
  color: #fff;
  background-color: #007DB3;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.1875rem 0.375rem;
  letter-spacing: 1px; }

.phase__summary {
  font-size: 1rem;
  line-height: 1.2; }

.local-header-nav {
  display: block;
  right: 12px;
  position: absolute;
  top: 0;
  height: 100%; }

.local-header-nav__item {
  display: block;
  margin-bottom: 0.75rem;
  position: relative;
  display: inline-block;
  margin-left: 1.5rem;
  margin-bottom: 0;
  line-height: 52px; }

.local-header-nav__link {
  text-decoration: none;
  color: #0076A8;
  font-size: 1rem;
  line-height: 1.2;
  font-size: 1.15rem;
  line-height: 1.2; }
  .local-header-nav__link:hover {
    color: #004785;
    text-decoration: underline; }
  .local-header-nav__link.is--active {
    font-weight: 700; }

.local-header-nav__btn {
  font-size: 0.85rem;
  line-height: 1.2;
  color: #004785;
  display: block;
  position: absolute;
  right: 12px;
  top: 18px;
  z-index: 800;
  text-align: center;
  display: none; }
  .local-header-nav__btn.is--active {
    color: #007DB3; }
  .local-header-nav__btn:focus {
    outline: 4px solid #ffcd04; }

.local-header-nav__btn--offset {
  right: 50px; }

.local-header-nav__btn--on {
  display: none; }

.is--active .local-header-nav__btn--off {
  display: none; }

.is--active .local-header-nav__btn--on {
  display: block; }

.table__container {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 1.5rem; }

.table {
  font-size: 1.15rem;
  line-height: 1.2;
  width: 100%; }

.table__caption {
  display: table-caption;
  text-align: left;
  margin-bottom: 0.75rem; }

.table__header {
  font-weight: 700;
  padding: 0.375rem 12px 0.375rem 0;
  border-bottom: 1px solid #eaeaea;
  text-align: left; }

.table__cell {
  padding: 0.375rem 12px 0.375rem 0;
  border-bottom: 1px solid #eaeaea;
  text-align: left; }

.table__header--right,
.table__cell--right {
  text-align: right;
  padding-right: 12px;
  padding-left: 0; }

.table__header:last-child,
.table__cell:last-child {
  padding-right: 0; }

.inset-text {
  border-left: 16px solid #eaeaea;
  padding: 1rem;
  margin: 1rem 0;
  font-size: 1.15rem;
  line-height: 1.2; }

.summary-list {
  margin-bottom: 1.5rem; }

.summary-list__row {
  border-bottom: 1px solid #b2b2b2;
  margin-bottom: 0.75rem; }
  .summary-list__row:after {
    content: "";
    display: block;
    clear: both; }

.summary-list__key {
  font-weight: 700;
  margin-bottom: 0.5rem;
  max-width: 100%; }

.summary-list__value {
  margin-bottom: 1rem;
  max-width: 100%; }

.summary-list__actions {
  margin-bottom: 0.75rem;
  max-width: 100%; }

.summary-list__row {
  margin-bottom: 0; }

.summary-list__key {
  margin-bottom: 0;
  padding: 0.75rem 0.75rem 0.75rem 0;
  float: left;
  width: 30%; }

.summary-list__value {
  margin-bottom: 0;
  float: left;
  width: 50%;
  padding: 0.75rem 0.75rem 0.75rem 0; }
  .summary-list__value:last-child {
    width: 70%; }

.summary-list__actions {
  margin-bottom: 0;
  text-align: right;
  float: left;
  width: 20%;
  padding: 0.75rem 0; }

.summary-list--stacked .summary-list__key {
  float: none;
  width: 100%;
  padding-right: 0;
  padding-bottom: 0; }

.summary-list--stacked .summary-list__value {
  width: 80%; }
  .summary-list--stacked .summary-list__value:last-child {
    width: 100%; }

.browse .browse-panes:after {
  content: "";
  display: block;
  clear: both; }

.nss-speak .address {
  border-left: 2px solid #007DB3;
  padding-left: 12px;
  margin-bottom: 1.5rem; }

.nss-speak .steps {
  padding-left: 0;
  margin-left: 0;
  overflow: hidden;
  margin-bottom: 1.5rem; }
  .nss-speak .steps > li {
    background-position: 0 .87em;
    background-repeat: no-repeat;
    list-style-type: decimal;
    margin-left: 0;
    margin-bottom: 0.375rem;
    padding: .75em 0 .75em 2.5em; }
    .nss-speak .steps > li:nth-child(1) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E1%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(2) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E2%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(3) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E3%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(4) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E4%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(5) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E5%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(6) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E6%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(7) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E7%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(8) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E8%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(9) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E9%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(10) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E10%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(11) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E11%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(12) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E12%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(13) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E13%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(14) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E14%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(15) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E15%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(16) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E16%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(17) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E17%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(18) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E18%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(19) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E19%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(20) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E20%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(21) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E21%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(22) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E22%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(23) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E23%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(24) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E24%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(25) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E25%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(26) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E26%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(27) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E27%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(28) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E28%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(29) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E29%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }
    .nss-speak .steps > li:nth-child(30) {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E30%3C/text%3E%3C/g%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: .2em .7em;
      background-size: 1.4em 1.4em; }

.section-group {
  margin-top: 60px; }
  .section-group:first-child {
    border-top: none;
    padding-top: 0;
    margin-top: 0; }

.section-group__header {
  border-top: 2px solid #004785;
  padding-top: 15px; }

.card--shadow {
  border: 1px solid #004785;
  box-shadow: 7px 7px 0 #fff, 8px 8px 0 #004785; }

.card--align-left .card__body {
  padding: 1.5rem 0; }

.card--min-height .card__body {
  min-height: 13rem; }

/* modifiers */
.category-item--isd {
  border-left-color: #6c2c91; }

.category-item--logistics {
  border-left-color: #00a94f; }

.category-item--snbts {
  border-left-color: #db3328; }

.category-item--hps {
  border-left-color: #78a541; }

.category-item--legal {
  border-left-color: #00704a; }

.category-item--practitioner {
  border-left-color: #7e3661; }

.category-item--it {
  border-left-color: #01a19a; }

.category-item--facilities {
  border-left-color: #5e937f; }

.category-item--pgms {
  border-left-color: #625faa; }

.category-item--procurement {
  border-left-color: #e46d25; }

.category-item--commissioning {
  border-left-color: #ec008c; }

.category-item--fraud {
  border-left-color: #bb0371; }

/* modifiers */
.input--search-icon {
  background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36' width='40' height='40'%3E%3Cpath d='M25.7 24.8L21.9 21c.7-1 1.1-2.2 1.1-3.5 0-3.6-2.9-6.5-6.5-6.5S10 13.9 10 17.5s2.9 6.5 6.5 6.5c1.6 0 3-.6 4.1-1.5l3.7 3.7 1.4-1.4zM12 17.5c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5-2 4.5-4.5 4.5-4.5-2-4.5-4.5z' fill='currentColor'%3E%3C/path%3E%3C/svg%3E") no-repeat -5px -3px;
  padding-left: 30px; }

.facet-tags {
  margin-bottom: 0.75rem; }

.facet-tags__group {
  padding: 5px; }
  .facet-tags__group:nth-child(odd) {
    background-color: #fafafa; }

.facet-tags__wrapper {
  vertical-align: middle;
  margin: 5px 0;
  display: inline-block; }

.facet-tags__wrapper:first-of-type .facet-tags__preposition:first-of-type {
  font-weight: bold; }
  .facet-tags__wrapper:first-of-type .facet-tags__preposition:first-of-type::first-letter {
    text-transform: uppercase; }

.facet-tags__preposition {
  padding: 0 5px 5px 2px;
  vertical-align: middle;
  text-transform: lowercase;
  display: table-cell;
  padding: 0 5px 0 2px; }

.facet-tag {
  display: block;
  position: relative;
  padding: 5px;
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  background-color: #fafafa;
  display: table-cell; }

.facet-tag__text {
  display: inline-block;
  margin-left: 0; }

.attachment {
  position: relative;
  padding: 15px 0 0 129px; }
  .attachment:after {
    content: '';
    display: table;
    clear: both; }

.attachment__thumb {
  position: relative;
  float: left;
  margin-top: 5px;
  margin-left: -124px;
  padding-bottom: 15px; }

.attachment__img {
  outline: 5px solid rgba(11, 12, 12, 0.1);
  box-shadow: 0 2px 2px rgba(11, 12, 12, 0.4);
  display: block;
  width: 99px;
  height: 140px;
  background: #fff; }

.attachment__title {
  font-size: 1.3rem;
  line-height: 1.2;
  font-size: 1.5rem;
  margin-bottom: 0.375rem; }

.attachment__meta {
  font-size: 1rem;
  line-height: 1.2;
  color: #707070; }

.search-results-info {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #b2b2b2; }

.search-results-info__count {
  margin-bottom: 0.75rem; }

.notice {
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border: 5px solid #004785; }

.notice__title {
  font-size: 1.3rem;
  line-height: 1.2;
  font-size: 1.5rem;
  margin-bottom: 0.75rem; }

.notice__description {
  font-size: 1.15rem;
  line-height: 1.2;
  margin: 0; }

.cookie-banner {
  display: none;
  padding: 1.5rem 0;
  background-color: #eaeaea; }
  .no-js .cookie-banner {
    display: block; }

.cookie-banner__message {
  font-size: 1.15rem;
  line-height: 1.2;
  display: inline-block;
  margin-bottom: 0.75rem; }

.cookie-banner__button .btn {
  width: 100%;
  width: 95%; }

.cookie-banner__button-accept {
  display: inline-block; }
  .no-js .cookie-banner__button-accept {
    display: none; }

.cookie-banner__confirmation {
  display: none;
  position: relative; }
  .cookie-banner__confirmation:focus {
    outline: none; }

.cookie-banner__confirmation-message,
.cookie-banner__hide-button {
  display: block;
  display: inline-block; }

.cookie-banner__confirmation-message {
  margin-bottom: 0.75rem;
  max-width: 90%;
  margin-bottom: 0; }

.cookie-banner__hide-button {
  font-size: 1.15rem;
  line-height: 1.2;
  outline: 0;
  border: 0;
  background: none;
  text-decoration: underline;
  color: #0076A8;
  padding: 0;
  margin-top: 0;
  position: absolute;
  right: 12px; }
  .cookie-banner__hide-button:hover {
    color: #0076A8;
    text-decoration: none;
    cursor: pointer; }
  .cookie-banner__hide-button:focus {
    color: #0076A8;
    text-decoration: none;
    outline: 4px solid #ffcd04 !important; }

.share-links__list-item {
  font-size: 1rem;
  line-height: 1.2;
  position: relative;
  display: inline-block;
  padding-left: 42px;
  padding-right: 20px;
  line-height: 32px; }

.share-links__link {
  font-weight: 700; }

.share-links__title {
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 0.75rem; }

.share-links__link-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  vertical-align: top; }

.secondary-nav {
  margin-top: 0;
  margin-bottom: 1.5rem;
  display: none;
  display: block; }

.secondary-nav__list {
  margin: 0;
  padding: 0; }

.secondary-nav__title {
  font-size: 1.3rem;
  line-height: 1.2;
  font-size: 1.5rem;
  padding-top: 0;
  padding-bottom: 10px;
  padding-left: 0;
  font-weight: 700; }

.secondary-nav__link {
  display: block;
  text-decoration: none;
  color: #191919;
  padding: 1em 0; }
  .secondary-nav__link:focus, .secondary-nav__link:hover {
    color: #191919; }

.secondary-nav__item {
  border-top: 1px solid #ddd; }
  .secondary-nav__item .secondary-nav__link {
    padding-left: 14.5px; }

.secondary-nav__item--nested {
  padding-left: 20px;
  border-top: none; }

.secondary-nav__link--active {
  border-left: 4px solid #007DB3;
  font-weight: 700; }

.secondary-nav__link--active-nested {
  background: #f2f2f2;
  border-left: 4px solid #007DB3; }

.inverse-header {
  color: #fff;
  background-color: #007DB3;
  padding: 1.875rem;
  margin-bottom: 1.5rem; }

.inverse-header__title, .inverse-header__meta {
  color: inherit; }

.sticky-element {
  position: absolute;
  bottom: 0; }
  .sticky-element--stuck-to-window {
    bottom: 0;
    position: fixed; }
  .sticky-element--enabled {
    transition: opacity, .3s, ease;
    opacity: 1; }
  .sticky-element--hidden {
    opacity: 0;
    pointer-events: none; }

.no-js .sticky-element {
  position: static; }

.back-to-top {
  font-size: 1rem;
  line-height: 1.2;
  display: inline-block;
  margin-bottom: 1.5rem;
  margin-left: 12px;
  margin-right: 12px; }

.back-to-top__icon {
  float: left;
  margin-right: .3em;
  width: .8em;
  height: .968em; }

.feedback {
  background: #fff;
  border-bottom: 1px solid #fff; }

.feedback__prompt {
  background-color: #004785;
  color: #fff;
  outline: 0;
  display: table;
  width: 100%; }
  .feedback__prompt:after {
    content: '';
    display: table;
    clear: both; }

.feedback__prompt-questions {
  text-align: center;
  border-bottom: 1px solid #fff;
  padding: 24px;
  box-sizing: border-box;
  width: 50%;
  display: table-cell;
  text-align: left;
  border-bottom: 0; }

.feedback__prompt-questions--something-is-wrong {
  text-align: center;
  text-align: right;
  vertical-align: bottom;
  float: none;
  vertical-align: baseline; }

.feedback__prompt-question,
.feedback__prompt-success {
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 700; }

.feedback__prompt-question {
  vertical-align: top;
  display: inline-block;
  margin: 0.75rem 1.5rem;
  margin-left: 0;
  margin-top: 0;
  display: block;
  display: inline-block;
  margin-top: 0.75rem; }

.feedback__prompt-link {
  font-size: 1rem;
  line-height: 1.2;
  background: transparent;
  box-shadow: 0 2px 0 #fff;
  border: 1px #fff solid;
  min-width: 100%;
  position: relative;
  min-width: 100px;
  margin-bottom: 0; }
  .feedback__prompt-link:hover {
    background: #191919;
    background: rgba(25, 25, 25, 0.2); }

.feedback__option-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 0.75rem;
  display: inline-block;
  margin-right: 12px;
  margin-top: 0; }

.feedback__option-list-item {
  display: inline-block; }

.feedback__option-list-item:first-child {
  margin-right: 20px; }

.feedback__panel {
  padding: 1rem 0;
  border-top: 10px solid #004785;
  padding: 1.875rem 0; }
  .feedback__panel .grid-row {
    clear: none; }

.feedback__panel-heading {
  font-size: 1.3rem;
  line-height: 1.2;
  font-size: 1.5rem;
  margin-bottom: 0.375rem; }

.feedback__panel-paragraph {
  line-height: 1.4;
  margin-bottom: 1.5rem; }

.feedback__close {
  float: right;
  margin: 0 5px 10px 0; }

.feedback__panel.js-feedback-hidden {
  display: none; }
  .no-js .feedback__panel.js-feedback-hidden {
    display: block; }

.no-js .js-feedback-show, .no-js
.feedback__prompt-success, .no-js
.feedback__prompt-questions, .no-js
.feedback__error-summary {
  display: none; }
  .no-js .js-feedback-show.js-feedback-hidden, .no-js
  .feedback__prompt-success.js-feedback-hidden, .no-js
  .feedback__prompt-questions.js-feedback-hidden, .no-js
  .feedback__error-summary.js-feedback-hidden {
    display: none; }

.js-feedback-show.js-feedback-hidden,
.feedback__prompt-success.js-feedback-hidden,
.feedback__prompt-questions.js-feedback-hidden,
.feedback__error-summary.js-feedback-hidden {
  display: none; }

.browse .browse-panes {
  padding-bottom: 24px;
  position: relative;
  padding-top: 24px; }
  .browse .browse-panes.section .root-pane {
    display: none;
    display: block;
    float: right;
    width: 25%; }
  .browse .browse-panes.section .section-pane {
    float: right;
    width: 30%;
    margin-right: 45%;
    width: 35%;
    margin-right: 40%; }
  .browse .browse-panes.subsection .root-pane,
  .browse .browse-panes.subsection .section-pane {
    display: none;
    display: block;
    float: right;
    width: 25%;
    color: #707070; }
    .browse .browse-panes.subsection .root-pane a,
    .browse .browse-panes.subsection .root-pane p,
    .browse .browse-panes.subsection .section-pane a,
    .browse .browse-panes.subsection .section-pane p {
      color: #707070; }
  .browse .browse-panes.subsection .section-pane {
    margin-left: -18%;
    width: 30%;
    margin-left: -13%;
    width: 25%; }
  .browse .browse-panes.subsection .subsection-pane {
    float: right;
    width: 50%;
    margin-right: 13%; }
  .browse .browse-panes .pane-inner {
    padding-bottom: 24px;
    border-left: 1px solid #bfc1c3;
    min-height: 950px;
    min-height: 700px; }
  .browse .browse-panes h1,
  .browse .browse-panes h2 {
    font-size: 1.6rem;
    line-height: 1.2;
    font-size: 1.8rem;
    margin-bottom: 8px;
    margin-top: 8px;
    outline: none;
    margin-top: 0;
    margin-bottom: 12px; }
  .browse .browse-panes .root-pane,
  .browse .browse-panes .section-pane {
    min-height: 20px; }
    .browse .browse-panes .root-pane h1,
    .browse .browse-panes .root-pane h2,
    .browse .browse-panes .section-pane h1,
    .browse .browse-panes .section-pane h2 {
      padding-left: 12px; }
    .browse .browse-panes .root-pane .sort-order,
    .browse .browse-panes .section-pane .sort-order {
      display: none; }
    .browse .browse-panes .root-pane ul li,
    .browse .browse-panes .section-pane ul li {
      list-style: none;
      position: relative; }
    .browse .browse-panes .root-pane ul a,
    .browse .browse-panes .section-pane ul a {
      font-size: 1.15rem;
      line-height: 1.2;
      position: relative;
      display: block;
      text-decoration: none;
      padding: 12px 25px 8px 0;
      padding: 12px 24px 8px 12px; }
      .browse .browse-panes .root-pane ul a:hover,
      .browse .browse-panes .section-pane ul a:hover {
        background: #eaeaea;
        color: #0076A8; }
      .browse .browse-panes .root-pane ul a:after,
      .browse .browse-panes .section-pane ul a:after {
        position: absolute;
        top: 50%;
        margin-top: -8px;
        right: 8px;
        float: right;
        content: "\203A"; }
      .browse .browse-panes .root-pane ul a.loading,
      .browse .browse-panes .section-pane ul a.loading {
        cursor: default;
        background: transparent url("/static/img/loading.gif") 95% 50% no-repeat; }
        .browse .browse-panes .root-pane ul a.loading:after,
        .browse .browse-panes .section-pane ul a.loading:after {
          content: ""; }
      .browse .browse-panes .root-pane ul a h3,
      .browse .browse-panes .section-pane ul a h3 {
        font-weight: bold; }
      .browse .browse-panes .root-pane ul a p,
      .browse .browse-panes .section-pane ul a p {
        color: #191919;
        font-size: 1rem;
        line-height: 1.2; }
    .browse .browse-panes .root-pane ul .active a,
    .browse .browse-panes .section-pane ul .active a {
      background: #004785;
      color: #fff; }
      .browse .browse-panes .root-pane ul .active a:hover,
      .browse .browse-panes .section-pane ul .active a:hover {
        background: #004785; }
      .browse .browse-panes .root-pane ul .active a p,
      .browse .browse-panes .section-pane ul .active a p {
        color: #fff; }
  .browse .browse-panes .section-pane {
    z-index: 2;
    background: #fff;
    position: relative; }
    .browse .browse-panes .section-pane.with-sort .pane-inner {
      padding-left: 24px; }
    .browse .browse-panes .section-pane.with-sort .pane-inner.alphabetical {
      padding-left: 96px; }
    .browse .browse-panes .section-pane.with-sort .sort-order {
      font-size: 1.3rem;
      line-height: 1.2;
      font-size: 1.5rem;
      float: left;
      display: block;
      width: 75px;
      margin-left: -90px;
      padding: 11px 0 12px 12px; }
  .browse .browse-panes .subsection-pane {
    /*
      .curated-list {
        ul li a {
          padding: $gutter-one-sixth $gutter $gutter-one-sixth 0;
        }
      }
      */ }
    .browse .browse-panes .subsection-pane .pane-inner.a-to-z {
      padding-left: 100px; }
    .browse .browse-panes .subsection-pane .pane-inner.curated-list {
      padding-left: 24px; }
    .browse .browse-panes .subsection-pane h1 {
      padding-left: 0; }
    .browse .browse-panes .subsection-pane .list-header {
      font-size: 1.15rem;
      line-height: 1.2;
      font-weight: 700;
      margin: 24px 0 0 0; }
    .browse .browse-panes .subsection-pane .curated-list .list-header {
      margin: 24px 0 4px 0; }
    .browse .browse-panes .subsection-pane .sort-order {
      display: none;
      display: block;
      font-size: 1.3rem;
      line-height: 1.2;
      font-size: 1.5rem;
      float: left;
      width: 75px;
      margin-left: -100px;
      padding: 11px 0 12px 12px; }
    .browse .browse-panes .subsection-pane ul {
      padding: 0;
      list-style: none; }
    .browse .browse-panes .subsection-pane ul li {
      padding: 0;
      margin: 0; }
    .browse .browse-panes .subsection-pane ul li a {
      font-size: 1.15rem;
      line-height: 1.2;
      font-weight: 700;
      display: block;
      text-decoration: none;
      padding: 8px 24px 8px 0; }
    .browse .browse-panes .subsection-pane .detailed-guidance {
      margin-top: 24px; }

.no-js .cookie-settings__form-wrapper {
  display: none; }

.cookie-settings__no-js {
  display: none; }
  .no-js .cookie-settings__no-js {
    display: block; }

.cookie-settings__confirmation {
  display: none; }
  .cookie-settings__confirmation:focus {
    outline: none; }
  .cookie-settings__confirmation .notice__description {
    margin-bottom: 0.375rem; }

.cookie-settings__prev-page {
  font-size: 1.15rem;
  line-height: 1.2; }

.departments-summary {
  border-bottom: 1px solid #b2b2b2;
  padding-bottom: 0.75rem; }
  .departments-summary:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both; }

.departments-summary__count {
  margin-top: -3px;
  font-size: 3rem;
  /* we do not have a mixin for this font size */
  line-height: 1;
  text-transform: none;
  font-weight: 700;
  float: left;
  padding-right: 12px;
  color: #707070; }

.departments-summary__text {
  font-size: 1.15rem;
  line-height: 1.2;
  font-weight: 700;
  line-height: 1.5; }

.news-story__image {
  margin-bottom: 1.5rem;
  width: 50%; }

.person__image {
  margin-bottom: 1.5rem;
  width: 50%; }

.home-header {
  background: #cfe5f7;
  overflow: hidden; }

.home-header__title {
  font-size: 2rem;
  line-height: 1.2;
  font-size: 2.6rem;
  margin: 1.5rem 0 0.75rem 0;
  font-weight: 300; }

.home-header__strapline {
  font-size: 1.15rem;
  line-height: 1.2;
  margin-bottom: 1rem; }

.home-header__search-form {
  margin-bottom: 1.5rem; }

.home-header__links {
  padding: 12px 0 12px 0;
  color: #fff;
  position: relative;
  z-index: 1;
  margin-top: 5px;
  padding: 12px 20px 12px 20px;
  background: #004785; }
  .home-header__links:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 0;
    bottom: -100px;
    width: 100%;
    background: #004785; }

.home-header__links-title {
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 0.25rem; }

.home-header__links-item {
  margin: 4px 0; }

.home-header__links-link {
  font-size: 1rem;
  line-height: 1.2;
  color: #fff; }
  .home-header__links-link:focus, .home-header__links-link:hover {
    color: #eaeaea; }

.home-services {
  margin: 3rem 0; }

.home-services__item {
  border-top: 3px solid #007DB3;
  padding-top: 0.75rem;
  margin-bottom: 1.5rem; }

.home-services__title {
  font-size: 1.15rem;
  line-height: 1.2;
  margin-bottom: 0.75rem; }

.home-services__summary {
  font-size: 1rem;
  line-height: 1.2; }

.html-attachment [data-module="sticky-element-container"] {
  position: relative; }

.feedback {
  display: inline-block;
  width: 100%; }
