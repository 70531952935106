.feedback {
    background: get-color(white);

    @include mq($from: medium) {
        border-bottom: 1px solid get-color(white);
    }
}

.feedback__prompt {
    @include clearfix;
    background-color: get-color(primary-blue);
    color: get-color(white);
    outline: 0;

    @include mq($from: medium) {
        display: table;
        width: 100%;
    }    
}

.feedback__prompt-questions {
    text-align: center;
    border-bottom: 1px solid get-color(white);
    padding: $gutter;
    box-sizing: border-box;

    @include mq($from: medium) {
        width: 50%;
        display: table-cell;
        text-align: left;
        border-bottom: 0;
    }    
}

.feedback__prompt-questions--something-is-wrong {
    text-align: center;
  
    @include mq($from: medium) {
      text-align: right;
      vertical-align: bottom;
      float: none;
    }

    @include mq($from: large) {
        vertical-align: baseline;
    }
}

.feedback__prompt-question,
.feedback__prompt-success {
    @include epsilon;
    font-weight: $bold;
}

.feedback__prompt-question {
    vertical-align: top;
    display: inline-block;
    margin: $baseline/2 $baseline;
    
    @include mq($from: medium) {
        margin-left: 0;
        margin-top: 0;
        display: block;
    }
    
    @include mq($from: large) {
        display: inline-block;
        margin-top: $baseline/2;
    }    
}

.feedback__prompt-link {
    @include epsilon;
    background: transparent;
    box-shadow: 0 2px 0 get-color(white);
    border: 1px get-color(white) solid;
    min-width: 100%;
    position: relative;

    &:hover {
        // backup style for browsers that don't support rgba
        background: get-color(off-black);
        background: rgba(get-color(off-black), .2);
      }

      @include mq($from: small) {
        min-width: 100px;
        margin-bottom: 0;
      }      
}

.feedback__option-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-top: $baseline/2;
  
    @include mq($from: small) {
        display: inline-block;
        margin-right: $gutter/2;
    }

    @include mq($from: medium) {
        margin-top: 0;
    }       
}
  
.feedback__option-list-item {
    @include mq($from: small) {
        display: inline-block;
    }
}
  
.feedback__option-list-item:first-child {
    @include mq($from: small) {
        margin-right: $gutter/1.2;
    }   
}

.feedback__panel {
    padding: $baseline / 1.5 0;
    border-top: 10px solid get-color(primary-blue);

    .grid-row{
        clear:none;
    }
  
    @include mq($from: medium) {
      padding: $baseline * 1.25 0;
    }
}

.feedback__panel-heading {
    @include heading-m;
}

.feedback__panel-paragraph {
    line-height:1.4;
    margin-bottom:$baseline;
}

.feedback__close {
    float: right;
    margin: 0 5px 10px 0;
}

// with js - hide panels with js-feedback-hidden class
// without js - show panels with js-feedback-hidden class
.feedback__panel.js-feedback-hidden {
    display: none;

    .no-js & {
        display:block;
    }       
}

// with js - show unless also has js-feedback-hidden class
// without js - hide
.js-feedback-show,
.feedback__prompt-success,
.feedback__prompt-questions,
.feedback__error-summary {
  .no-js & {
    display: none;
    
    // required to ensure equal specificity with .no-js .feedback__panel.js-feedback-hidden
    &.js-feedback-hidden {
        display: none;
      }    
  }

  &.js-feedback-hidden {
    display: none;
  }  
}