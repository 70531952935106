.sticky-element {
    position: absolute;
    bottom: 0;
  
    &--stuck-to-window {
        bottom: 0;
        position: fixed;
    }
  
    &--enabled {
        transition: opacity, .3s, ease;
        opacity: 1;
        
        @include mq($until: medium) {
            position: static;
        }        
    }
  
    &--hidden {
        opacity: 0;
        pointer-events: none;
    }
}

.no-js .sticky-element {
    position: static;
}