.secondary-nav {
    margin-top: 0;
    margin-bottom: $baseline;
    display:none;
    
    @include mq($from: medium) {
        display: block;
    }
}

.secondary-nav__list {
    margin: 0;
    padding: 0;
}

.secondary-nav__title {
    @include gamma;
    padding-top: 0;
    padding-bottom: 10px;
    padding-left: 0;
    font-weight: $bold;
}

.secondary-nav__link {
    display: block;
    text-decoration: none;
    color: get-color(off-black);
    padding: 1em 0;    
    
    &:focus, &:hover{
        color: get-color(off-black);
    }
}

.secondary-nav__item {
    border-top: 1px solid #ddd;

    .secondary-nav__link {
        padding-left: 14.5px;
    }
}

.secondary-nav__item--nested {
    padding-left: 20px;
    border-top: none;
}

.secondary-nav__link--active {
    border-left: 4px solid get-color(secondary-blue);
    font-weight: 700;
}

.secondary-nav__link--active-nested {
    background: $concrete-white;
    border-left: 4px solid get-color(secondary-blue);
}