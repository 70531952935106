.section-group {    
    margin-top: 60px;    
    &:first-child{
      border-top:none;
      padding-top: 0;
      margin-top: 0;
    }
  }

  .section-group__header{
    border-top: 2px solid get-color(primary-blue);
    padding-top: 15px;
  }