.inverse-header {
    color: get-color(white);
    background-color: get-color(secondary-blue);
    padding: $baseline * 1.25;
    margin-bottom: $baseline;
}

%inverse-header__text {
    color:inherit;
}

.inverse-header__title{
    @extend %inverse-header__text;  
}

.inverse-header__meta {
    @extend %inverse-header__text;
}