// scss-lint:disable NestingDepth
.browse {
  .browse-panes {
    @extend %contain-floats;
    padding-bottom: $gutter;
    position: relative;

    @include mq($from: medium) {
      padding-top: $gutter;
    }

    &.section {
      .root-pane {
        display: none; // hide on mobile

        @include mq($from: medium) {
          display: block;
          float: right;
          width: 25%;          
        }
      }

      .section-pane {
        @include mq($from: medium) {
          float: right;
          width: 30%;
          margin-right: 45%;
        }

        @include mq($from: large) {
          width: 35%;
          margin-right: 40%;          
        }
      }
    }

    &.subsection {
      .root-pane,
      .section-pane {
        display: none; // hide on mobile

        @include mq($from: medium) {
          display: block;
          float: right;
          width: 25%;          
        }

        color: get-color(mid-grey);

        a,
        p {
          color: get-color(mid-grey);
        }
      }

      .section-pane {
        @include mq($from: medium) {
          margin-left: -18%;
          width: 30%;
        }

        @include mq($from: large) {
          margin-left: -13%;
          width: 25%;
        }
      }

      .subsection-pane {
        @include mq($from: medium) {
          float: right;
          width: 50%;
          margin-right: 13%;
        }
      }
    }

    .pane-inner {
      padding-bottom: $gutter;
    
      @include mq($from: medium) {
        border-left: 1px solid $border-colour;
        min-height: 950px;
      }

      @include mq($from: large) {
        min-height: 700px;        
      }      
    }

    h1,
    h2 {
      @include beta;
      margin-bottom: $gutter-one-third;
      margin-top: $gutter-one-third;
      outline: none;

      @include mq($from: medium) {
        margin-top: 0;
        margin-bottom: $gutter-half;        
      }      
    }

    .root-pane,
    .section-pane {
      min-height: 20px;

      h1,
      h2 {
        @include mq($from: medium) {
          padding-left: $gutter-half;          
        }
      }

      .sort-order {
        display: none;
      }

      ul li {
        list-style: none;
        position: relative;
      }

      ul a {
        @include delta;
        position: relative;
        display: block;        
        text-decoration: none;
        padding: 12px 25px 8px 0;

        @include mq($from: medium) {
          padding: 12px $gutter 8px $gutter-half;
        }

        &:hover {
          background: get-color(light-grey);
          color: get-color(link-color);
        }

        &:after {
          position: absolute;
          top: 50%;
          margin-top: -8px;
          right: $gutter-one-third;
          float: right;
          content: "\203A";
        }

        &.loading {
          cursor: default;
          background: transparent nss-image-url("loading.gif") 95% 50% no-repeat;

          &:after {
            content: "";
          }
        }

        h3 {
          font-weight: bold;
        }

        p {
          color: get-color(off-black);
          @include epsilon;
        }
      }

      ul .active a {
        background: get-color(primary-blue);
        color: get-color(white);

        &:hover {
          background: get-color(primary-blue);
        }

        p {
          color: get-color(white);
        }
      }
    }

    .section-pane {
      z-index: 2;
      background: get-color(white);
      position: relative;

      @include mq($from: large) {
        &.with-sort {
          .pane-inner {
            padding-left: $gutter;
          }

          .pane-inner.alphabetical {
            padding-left: 96px;
          }

          .sort-order {
            @include gamma;
            float: left;
            display: block;
            width: 75px;
            margin-left: -90px;
            padding: 11px 0 $gutter-half $gutter-half;
          }
        }        
      }
    }

    .subsection-pane {
      @include mq($from: medium) {
        .pane-inner {
          &.a-to-z {
            padding-left: 100px;
          }

          &.curated-list {
            padding-left: $gutter;
          }
        }        
      }

      h1 {
        padding-left: 0;
      }

      .list-header {
        @include delta;
        font-weight: $bold;
        margin: $gutter 0 0 0;
      }

      .curated-list {
        .list-header {
          margin: $gutter 0 $gutter-one-sixth 0;
        }
      }

      .sort-order {
        display: none;

        @include mq($from: medium) {
          display: block;
          @include gamma;
          float: left;
          width: 75px;
          margin-left: -100px;
          padding: 11px 0 $gutter-half $gutter-half;          
        }
      }

      ul {
        padding: 0;
        list-style: none;
      }

      ul li {
        padding: 0;
        margin: 0;
      }

      ul li a {
        @include delta;
        font-weight: $bold;
        display: block;
        text-decoration: none;
        padding: $gutter-one-third $gutter $gutter-one-third 0;
      }
      /*
      .curated-list {
        ul li a {
          padding: $gutter-one-sixth $gutter $gutter-one-sixth 0;
        }
      }
      */

      .detailed-guidance {
        margin-top: $gutter;
      }
    }
  }
}
// scss-lint:enable NestingDepth