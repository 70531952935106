// // Card
// // 
// // --------------------------------------------------
.card--shadow {
    border: 1px solid get-color(primary-blue);
    box-shadow: 7px 7px 0 #fff, 8px 8px 0 get-color(primary-blue);
}

.card--align-left {
    .card__body {
        padding: 1.5rem 0;
    }    
}

.card--min-height {
    .card__body {
        min-height: 13rem;
    }
}