.nss-speak {
    .steps {
        padding-left: 0;
        margin-left: 0;
        overflow: hidden;
        margin-bottom: $baseline;
        > li {
            background-position: 0 .87em;
            background-repeat: no-repeat;
            list-style-type: decimal;
            margin-left: 0;
            margin-bottom: $baseline/4;
            padding: .75em 0 .75em 2.5em;
        
            @for $i from 1 through 30 {
              &:nth-child(#{$i}) {
                background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 250' preserveAspectRatio='xMinYMin meet'%3E%3Cg%3E%3Ccircle r='50%25' cx='50%25' cy='50%25' class='circle-back'%3E%3C/circle%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' dy='0.3em' font-family='Neue Helvetica W01,Helvetica Neue,Helvetica,Arial,sans-serif' font-size='8rem' fill='%23ffffff'%3E#{$i}%3C/text%3E%3C/g%3E%3C/svg%3E ");
                background-repeat: no-repeat;
                background-position: .2em .7em;
                background-size: 1.4em 1.4em;
              }
            }
        }
    }
}