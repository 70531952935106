.back-to-top {
    @include epsilon;
    display: inline-block;
    margin-bottom: $baseline;
    margin-left: $gutter/2;
    margin-right: $gutter/2;
}
  
.back-to-top__icon {
    float: left;
    margin-right: .3em;
    width: .8em;
    height: .968em;
}