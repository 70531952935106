@charset 'UTF-8';

// Import all compiled styles from NSS Toolkit as base
@import 'nss-toolkit-ie8';

@import 'app/index';

.feedback {
    display: inline-block;
    width: 100%;
}