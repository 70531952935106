// // Facet-tags
// //
// // Adapted from GOV.UK 
// // https://github.com/alphagov/finder-frontend/blob/master/app/assets/stylesheets/finder_frontend.scss
// // --------------------------------------------------

.facet-tags {
    margin-bottom: $baseline/2;
}

.facet-tags__group {
    padding: 5px;

    &:nth-child(odd){
        background-color: get-color(off-white);
    }
}

.facet-tags__wrapper {
    vertical-align: middle;
    margin: 5px 0;

    @include mq($from: medium) {
        display: inline-block;  
    }    
}

.facet-tags__wrapper:first-of-type .facet-tags__preposition:first-of-type {
    font-weight: bold;

    &::first-letter {
        text-transform: uppercase;
      }    
}

.facet-tags__preposition {
    padding: 0 5px 5px 2px;
    vertical-align: middle;
    text-transform: lowercase;

    @include mq($from: medium) {
        display: table-cell;
        padding: 0 5px 0 2px;
    }     
}

.facet-tag {
    display: block;
    position: relative;
    padding: 5px;
    border: 1px solid get-color(grey);
    border-radius: 5px;
    background-color: get-color(off-white);

    @include mq($from: medium) {
        display: table-cell;
    }         
}

.facet-tag__text {
    display: inline-block;
    margin-left: 0;
}
