.notice {
  padding: $baseline;
  margin-bottom: $baseline;

  border: 5px solid get-color(primary-blue);
}

.notice__title {
  @include gamma;
  margin-bottom: $baseline/2;
}

.notice__description {
  @include delta;
  margin: 0;
}