.home-header {
    background: get-color(light-blue);
    overflow: hidden;
}

.home-header__title {
    @include alpha;
    margin:$baseline 0 $baseline/2 0;
    font-weight: $light;
}

.home-header__strapline {
    @include delta;
    margin-bottom:$baseline/1.5; 
}


.home-header__search-form {
    margin-bottom: $baseline;
}

.home-header__links-wrapper {
    @include mq($until: medium) {
        background: get-color(primary-blue);
    }
}

.home-header__links {
    padding: 12px 0 12px 0;
    color:get-color(white);

    @include mq($from: medium) {
        position: relative;
        z-index: 1;
        margin-top: 5px;
        padding: 12px 20px 12px 20px;
        background: get-color(primary-blue);        

        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            top: 10px;
            left: 0;
            bottom: -100px;
            width: 100%;
            background: get-color(primary-blue);
        }
    }    
}

.home-header__links-title {
    @include epsilon;
    margin-bottom: $baseline/6;
}

.home-header__links-item {
    margin: 4px 0;
}

.home-header__links-link {
    @include epsilon;
    color: get-color(white);

    &:focus,
    &:hover {
        color:get-color(light-grey);
    }    
}

.home-services {
    margin: $baseline*2 0;
}

.home-services__item {
    border-top:3px solid get-color(secondary-blue);
    padding-top:$baseline/2;
    margin-bottom:$baseline;        
}

.home-services__title{
    @include delta;
    margin-bottom:$baseline/2; 
}

.home-services__summary {
    @include epsilon;
}