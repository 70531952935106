$cookie-banner-background: get-color(light-grey);

.cookie-banner {
  display: none; // shown with JS
  padding: $baseline 0;
  background-color: $cookie-banner-background;  

  .no-js & {
    display: block; // always on for non-JS
  }    
}

.cookie-banner__message {
  @include delta;
  display: inline-block;
  margin-bottom: $baseline/2;
}

.cookie-banner__button {  
  .btn {
    width:100%;

    @include mq($from: large){
      width:95%;
    }
  }
}

// Only show accept button if users have js and can accept
.cookie-banner__button-accept {
  display: inline-block;
  .no-js & {
    display: none; // off if no js
  }     
}

.cookie-banner__confirmation {
  display: none;
  position: relative;

  // This element is focused using JavaScript so that it's being read out by screen readers
  // for this reason we don't want to show the default outline or emphasise it visually
  &:focus {
    outline: none;
  }
}

.cookie-banner__confirmation-message,
.cookie-banner__hide-button {
  display: block;

  @include mq($from: large) {
    display: inline-block;
  }
}

.cookie-banner__confirmation-message {
  margin-bottom: $baseline/2;
  @include mq($from: large) {
    max-width: 90%;
    margin-bottom: 0;
  } 
}

.cookie-banner__hide-button {
  @include delta;
  outline: 0;
  border: 0;
  background: none;
  text-decoration: underline;
  color:get-color(link-color);
  padding: 0;

  &:hover {
    color: get-color(link-color);
    text-decoration:none;
    cursor: pointer;
  }

  &:focus {
    color:get-color(link-color);
    text-decoration:none;
    outline: 4px solid get-color(highlight) !important;
  }

  @include mq($from: large) {
    margin-top: 0;
    position: absolute;
    right: $gutter/2;
  }
}