  
$share-link-width: 32px;
$share-link-height: 32px;

.share-links__list-item {
    @include epsilon;
    position: relative;
    display:inline-block;
    padding-left: ($share-link-width + 10px);
    padding-right: 20px;
    line-height: $share-link-height;
}

.share-links__link {
    font-weight: $bold;
}

.share-links__title {
    @include epsilon;
    margin-bottom: $baseline / 2;
  }

.share-links__link-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: $share-link-width;
    height: $share-link-height;
    vertical-align: top;   
}