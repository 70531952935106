.cookie-settings__form-wrapper {
    .no-js & {
      display: none;
    }
  }
  
.cookie-settings__no-js {
  display: none;
  
  .no-js & {
    display: block;
  }
}

.cookie-settings__confirmation {
  display: none;
  
  // This element is focused using JavaScript so that it's being read out by screen readers
  // for this reason we don't want to show the default outline or emphasise it visually
  &:focus {
    outline: none;
  }
    
  .notice__description {
    margin-bottom: $baseline/4;
  }
}

.cookie-settings__prev-page {
  @include delta;
}